import React from "react"

const NotFoundPage = () => {
  React.useEffect(() => {
    window.location.href = window.location.origin
  }, [])
  return null
}

export default NotFoundPage
